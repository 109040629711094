import React from "react";

export const Thankyou = (props) => {

    return (
        <div id="thankyou">
            <div className="wrapper-1">
                <div className="wrapper-2">
                    <h1>Thank you!</h1>
                    <p>for inquiring about the High West Estate Management.</p>
                    <p>you should receive a confirmation email soon</p>
                </div>
            </div>
        </div>
    )
}